<template>
    <card>
        <template slot="header">
            <h3 v-if="modalData.paymentMethod == 'bankTransfer'" class="text-primary">
                
                <mdicon name="clock-outline" size="38" class="tim-icons"/>&nbsp;&nbsp;&nbsp;Payment Pending
            </h3>
            <h3 v-else class="text-success">
                <mdicon name="check-circle-outline" size="38" class="tim-icons"/>&nbsp;&nbsp;&nbsp;Payment Success
            </h3>
        </template>
        <div id="card-success-wrapper">
            <div class="row justify-content-center">
                <div class="col-lg-8">

                    <h4 :class="{
                        'text-success': modalData.paymentMethod != 'bankTransfer',
                        'text-info': modalData.paymentMethod == 'bankTransfer'

                    }"><u>Your payment is processing.</u></h4>
                    <p v-if="modalData.paymentMethod == 'bankTransfer'">
                        As soon as we get bank transfer confirmation, your balance will be updated,<br> This process can take up to 3-5 days. <br>
                        A copy of the invoice will be sent to {{ modalData.billing_entity_email }} and the payment will be available on the payment history page.
                    </p>
                    <p v-else>Your balance will be updated and an invoice will be available once completed.</p>
                    <br>
                    <div class="row" v-if="modalData.paymentMethod == 'bankTransfer'">
                        <div class="col-lg-12">
                            <h6>Payment Reference: {{ modalData.nextProformaInvoiceNumber }}</h6>
                            <base-button simple type="primary" @click="downLoadInvoice">
                                DOWNLOAD INVOICE
                            </base-button>
                        </div>
                    </div>
                    <br>
                    <p class="text-white">
                        Have questions?
                        <base-button 
                            class="border-0 d-inline-block"
                            native-type="submit" 
                            type="primary"
                            simple
                            @click="toggleLiveAgent">
                            Get in touch
                        </base-button>
                    </p>
                    <hr/>
                    <div class="row">
                        <div class="col-lg-8">
                            <h6>Billing Information</h6>
                            <Details :billing="modalData" :isEdit="false"/>
                        </div>
                        <div class="col-lg-4">
                            
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
        <template slot="footer">
            <div class="row justify-content-center">
                
                <p v-if="modalData.paymentMethod == 'bankTransfer'" class="text-info">Thank you for your payment</p>
                <p v-else class="text-success">Thank you for your payment</p>
            </div>
        </template>
    </card>
</template>

<script>
import Details from '../../Billing/Details.vue';
export default {
    components:{
        Details
    },
    props:[
        'creditCardPaymentId',
        'creditCardPaymentAmount',
        'modalData',
        'toggleLiveAgent',
        'proforma'
    ],
    data(){
        return {
        }
    },
    methods:{
        async downLoadInvoice(){

            const { proforma } = this.modalData
            const { proforma_invoice_number } = proforma
            //const res = await this.$http.post(`${process.env.VUE_APP_API_ENDPOINT}/billing/download-proforma-invoice`, proforma)
            const res = await this.$http.post(`${process.env.VUE_APP_API_ENDPOINT}/billing/invoice/proforma/download`, proforma)
            if(res && res.status == 200){
                var docUrl = document.createElement('a');
                docUrl.href=`data:application/pdf;base64,${res.data}`
                docUrl.setAttribute('download', proforma_invoice_number + '.pdf');   
                document.body.appendChild(docUrl);
                docUrl.click();
            }
        }
    }
}
</script>

<style>

</style>