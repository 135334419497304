<template>
  <card>
    <template slot="header">
      <h3 class="text-danger"><mdicon name="close-circle-outline" size="38" class="tim-icons "/>&nbsp;&nbsp;&nbsp;Payment Failed</h3>
    </template>
    <div id="card-success-wrapper">
      <div class="row justify-content-center">
          <div class="col-lg-8">
              <p class="text-danger">
                  Your payment has failed. <br>
                  Please check your card details and try again.<br>
              </p>
              <br>
              <p class="text-white">
                  Have questions?
                  <base-button 
                    class="border-0 d-inline-block"
                    native-type="submit" 
                    type="primary"
                    simple
                    @click="toggleLiveAgent">
                    Get in touch
                  </base-button>
              </p>
              <hr/>
              <div class="row">
                  <div class="col-lg-8">
                      <h6>Billing Information</h6>
                      <Details :billing="modalData" :isEdit="false"/>
                  </div>
                  <div class="col-lg-4">
                    <base-button simple type="info"  @click="cancel">
                        Try Again
                    </base-button>
                  </div>
              </div>
          </div>
          
      </div>
      
    </div>

    <template slot="footer">
      <div class="row justify-content-center">
        <p class="text-danger">Apologies for the inconvenience</p>
      </div>
    </template>
  </card>
</template>

<script>
import Details from '../../Billing/Details.vue';
export default {
  components:{
    Details
  },
  props:[
    'cancel',
    'modalData',
    'toggleLiveAgent'
  ]
}
</script>

<style>

</style>