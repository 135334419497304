<template>
    <div>
        <div class="d-flex justify-content-center">
            <h2>Add Funds To Your Account</h2>  
        </div>
        <base-alert type="warning" dismissible icon="tim-icons icon-bell-55">
            Payment processing occurs exclusively on weekdays. Any payments made during weekends will be processed on the next business day.
        </base-alert>
        
        <div class="row d-flex justify-content-center">
            <div class="col-lg-7" v-if="(genericError || confirmError) && !paymentStatus">
                <genericError v-if="genericError"
                    :cancel="cancel" 
                    :genericError="genericError"
                    :modalData="modalData" 
                    :toggleLiveAgent="toggleLiveAgent"
                />
                
                <confirmError v-else 
                    :cancel="cancel" 
                    :confirmError="confirmError"
                    :modalData="modalData" 
                    :toggleLiveAgent="toggleLiveAgent"
                />
            </div>
            <div class="col-lg-7" v-else-if="paymentStatus">
                <paymentSuccess 
                    :modalData="modalData"
                    :proforma="proforma"
                    :toggleLiveAgent="toggleLiveAgent"
                />
            </div>
            <div class="col-lg-7" v-else-if="wizardCompleted">
                
                <div v-if="paymentMethod == 'creditCard'">
                    <CardResult 
                        :modalData="modalData" 
                        :paymentStatus="paymentStatus" 
                        :cancel="cancel" 
                        :genericError="genericError"
                        :confirmError="confirmError"
                        :wizardCompleted="wizardCompleted"
                    />
                </div>
                
                <div v-else-if="paymentMethod == 'sepaTransfer'">
                    <SepaSummery :modalData="modalData" :cancel="cancel" />
                </div>

                <div v-else-if="paymentMethod == 'bankTransfer'">
                    
                    <BankSummery 
                        :modalData="modalData"
                        :cancel="cancel" 
                    />
                </div>


            </div>
            <div class="col-lg-7" v-else>
                <div id="payment-wizzard-wrapper">
                    <Wizzard ref="wizzard" 
                        v-model="modalData" 
                        :paymentMethod="paymentMethod"
                        :wizardComplete="wizardComplete" 
                        :wizardCompleted="wizardCompleted"
                        @step="step"
                        @loading="toggleLoading"
                    />
                </div>
            </div>
            <div class="col-lg-3">  
                
                <AmountSummery 
                    :nextStep="nextStep" 
                    :summeryData="modalData" 
                    :loading="loading" 
                    :completePayment="completePayment" 
                    :wizardCompleted="wizardCompleted"
                    :paymentStatus="paymentStatus"
                />
            </div>
            
        </div>
    </div>
</template>

<script>
import Wizzard from './Wizzard.vue'
import AmountSummery from './AmountSummery.vue'
import { CardResult } from './Summery/Card'
import { Summery as SepaSummery } from './Summery/Sepa'
import BankSummery from './Summery/bank/Layout.vue'

import { BaseAlert, BaseProgress } from 'src/components'
import { paymentSuccess, paymentFailed, genericError, confirmError } from './Summery/index'

import { EventBus } from '../../../plugins/eventBus';

export default {
    components:{
        Wizzard,
        AmountSummery,
        BaseAlert,
        BaseProgress,
        CardResult,
        SepaSummery,
        BankSummery,
        paymentSuccess, paymentFailed, genericError, confirmError,
        
    },
    mounted() {
        // Listen for the 'finish-progress' event
        //EventBus.$on('start-progress', this.toggleLoading(true));
        //EventBus.$on('finish-progress', this.toggleLoading(false));
        //EventBus.$on('fail-progress', this.toggleLoading(false));
        EventBus.$on('start-progress', this.startLoading.bind(this));
        EventBus.$on('finish-progress', this.stopLoading.bind(this));
        //EventBus.$on('finish-progress', this.toggleLoading);
        EventBus.$on('fail-progress',  this.stopLoading.bind(this));
        
        
    },
    data(){
        return {
            proforma: {},
            wizardCompleted: false,
            modalData: {},
            loading: false,
            paymentMethod: 'creditCard',
            completePaymentData: null,
            paymetnConfirming: false,
            paymentStatus: null,
            paymentIntent: null,
            confirmedIntent: null,
            paymentFinished: false,
            genericError: null,
            confirmError: null,
            nextPaymentRef: null,
        }
    },
    methods: {
        toggleLiveAgent(){
            EventBus.$emit('show-agent');
        },
        async cancel(){
            this.wizardCompleted = false
            this.paymentStatus = ""
            this.genericError = ""
            this.confirmError = null
            this.modalData = {}
        },
        step(step){
            this.wizardCompleted = false
        },
        async newWindowMessages(evnet){
            const { type } = evnet.data

            if(!type) return

            //console.log(evnet.data)

            this.loading = false

            switch (type) {
                case "creditCardconfirmSuccess":
                    const { paymentStatus, paymentIntent } = evnet.data
                    this.paymentStatus = paymentStatus
                    this.paymentIntent = paymentIntent
                    return
                case "confirmedIntent":
                    const { confirmedIntent } = evnet.data
                    this.paymentIntent = confirmedIntent
                    return
                case "creditCardconfirmError":
                    const { confirmError } = evnet.data
                    if(/limit/i.test(confirmError.code)){
                        confirmError.message = 'We cannot process SEPA payments at this time. please try a different payment type'
                    }
                    this.confirmError = confirmError
                    return
                case "genericError":
                    const { genericError } = evnet.data
                    this.genericError = genericError
                    return
                default:
                    this.genericError = 'Generic error'
            }

        },
        newStripeRedirectWindow(cfg){
            window.addEventListener('message', this.newWindowMessages);

            const redirectWindow = window.open(
                `${window.location.origin}/stripe/redirect`, 
                'Credit Card Payment', 
                'toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=800,height=600'
            );

            redirectWindow.onload = function() {
                redirectWindow.postMessage(cfg, '*');
            };

            return redirectWindow
        },
        async completePayment(){
            const { paymentMethod:paymentMethodType , methodObject, intent } = this.modalData
            
            if(paymentMethodType == "bankTransfer"){
                const { proforma } = this.modalData
                if(proforma == 'newProforma'){    
                    //
                    const res = await this.$http.delete(`${process.env.VUE_APP_API_ENDPOINT}/billing/stripe/payment/intent/delete/${intent.id}` )
                    const { status } = res
                    if(status == 200){
                        
                        const res = await this.$http.post(`${process.env.VUE_APP_API_ENDPOINT}/billing/invoice/proforma/create`, this.modalData )   
                        const { status, statusText, data } = res
                        if(status == 200) {
                            this.modalData = {...this.modalData, ...{ proforma: data }}
                            const {nextProformaInvoiceNumber} = this.modalData
                            this.paymentStatus = `Payment Invoice Ready ${nextProformaInvoiceNumber}`
                            this.paymentStatus = 'succeeded'
                        }else{
                            confirmError = statusText
                        }
                    }else{
                       this.cancel() 
                    }
                }else{
                    this.modalData = {...this.modalData, proforma }
                    const {  proforma_invoice_number} = proforma
                    this.paymentStatus = `Payment Invoice Ready ${proforma_invoice_number}`
                    this.paymentStatus = 'succeeded'
                }
                
                return
            }

            this.loading = true
            const { id:method } = methodObject
            const { client_secret:clientSecret } = intent
            
            if ([paymentMethodType, methodObject, clientSecret].every(v => v !== null)) {
                const redirectWindow = this.newStripeRedirectWindow({  paymentMethodType, clientSecret, method })
                if(!redirectWindow) return
            }else{
                this.$toast.error('Something went wrong')
            }

        },
        async wizardComplete(){
            //this.loading = true
            this.wizardCompleted = true
            //const { paymentMethod, methodObject, intent } = this.modalData
            const { paymentMethod } = this.modalData
            
            this.paymentMethod = paymentMethod


            if(this.paymentMethod == 'bankTransfer') {
                const { proforma } = this.modalData
                let nextProformaInvoiceNumber = null
                if(proforma == 'newProforma'){
                    nextProformaInvoiceNumber = Math.floor(100000 + Math.random() * 900000)
                }else{
                    const {proforma_invoice_number} = proforma
                    nextProformaInvoiceNumber = proforma_invoice_number
                }
                this.modalData = {
                    ...this.modalData,
                    nextProformaInvoiceNumber
                }
            }
                
        },
        async nextStep(){

            this.wizardCompleted = false
            const wizzard = this.$refs['wizzard'] 
            const currTab = wizzard.getActiveTabIndex()
            const nextTab = `step${currTab + 1}`
            await wizzard.wizzardNextTab(nextTab)
        },
        startLoading () {this.toggleLoading(true)},
        stopLoading () {this.toggleLoading(false)},
        toggleLoading (value) {
            this.loading = value
        },
    }
}
</script>

<style>
    #payment-wizzard-wrapper .card-body {
        margin-top: 0px;
    }
</style>