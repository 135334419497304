<template>
  <div class="row justify-content-center">
    <card>
      <template slot="header">
          <div class="row w-100 justify-content-center">
              <h3 class="info-text"> Payment Details</h3>
          </div>
      </template>  
      <div class="row w-100 justify-content-center">
        <tbody>
            <tr>
                <th scope="row" class="text-white">Payment Method</th>
                <td class="text-white">&nbsp;&nbsp;<Sepa/> &nbsp;&nbsp; SEPA</td>
            </tr>
            <tr>
                <th scope="row" class="text-white">Payment Reference</th>
                <td class="text-white">&nbsp;&nbsp;<u>{{ modalData.intent.id.split(/\_/)[1] }}</u></td>
            </tr>
            <tr>
                <th scope="row" class="text-white">Payment Customer Id</th>
                <td class="text-white">&nbsp;&nbsp;<u>{{ modalData.billing_stripe_customer_id.split(/\_/)[1] }}</u></td>
            </tr>
        </tbody>
      </div>
      <base-button simple type="muted"  @click="cancel">
          Cancel
      </base-button>
      
    </card>
  </div>
</template>

<script>
import { Sepa } from '../../Stripe/brandSvgs'
export default {
  components: {
    Sepa
  },
  props:{
    modalData: {
      type: Object,
      default: () => {}
    },
    cancel: {
      type: Function
    }
  },
  methods: {
    validate() {
        if(this.paymentStatus == null)
            return this.$emit("on-validated", true, {});
    }
  }
}
</script>

<style>
.no-border-table, .no-border-table th, .no-border-table td {
  border: none !important;
}
</style>