<template>
  <div>
    
    <CardGenericError 
        v-if="genericError"
        :modalData="modalData" 
        :cancel="cancel" 
        :genericError="genericError"
        :toggleLiveAgent="toggleLiveAgent"
    />
    
    <CardConfirmError 
        v-else-if="confirmError"
        :modalData="modalData" 
        :cancel="cancel" 
        :confirmError="confirmError"
        :toggleLiveAgent="toggleLiveAgent"
    />
    <div v-else-if="paymentStatus">
        <CardPaymentSuccess 
            v-if="paymentStatus == 'succeeded'" 
            :modalData="modalData"
            :toggleLiveAgent="toggleLiveAgent"
        />
        <CardPaymentFailed v-else 
            :modalData="modalData" 
            :cancel="cancel"
            :toggleLiveAgent="toggleLiveAgent"
        />
    </div>
    <Card 
        v-else-if="wizardCompleted"
        :paymentStatus="paymentStatus"
        :summeryData="modalData"
        :cancel="cancel"
    />
  </div>
</template>

<script>
import { EventBus } from '../../../../../plugins/eventBus'
import { 
    Card, 
    CardPaymentSuccess, 
    CardPaymentFailed,
    CardGenericError,
    CardConfirmError
} from './'

export default {
    components: {
        Card, 
        CardPaymentSuccess, 
        CardPaymentFailed, 
        CardGenericError,
        CardConfirmError
    },
    props:{
        modalData: {
            type: Object
        },
        paymentStatus: {
            type: String
        },
        cancel: {
            type: Function
        },
        genericError: {
            type: String
        },
        confirmError: {
            type: Object
        },
        wizardCompleted: {
            type: Boolean
        }
    },
    methods: {
        toggleLiveAgent(){
            EventBus.$emit('show-agent');
        }
    }
}
</script>

<style>

</style>