<template>
  <div>
    SEPA RESULTS!!
  </div>
    
</template>

<script>
//import { 
//    Card, 
//    CardPaymentSuccess, 
//    CardPaymentFailed,
//    CardGenericError,
//    CardConfirmError
//} from './'

export default {
    components: {
  //      Card, 
  //      CardPaymentSuccess, 
  //      CardPaymentFailed, 
  //      CardGenericError,
  //      CardConfirmError
    },
    props:{
        modalData: {
            type: Object
        },
        paymentStatus: {
            type: String
        },
        cancel: {
            type: Function
        },
        genericError: {
            type: String
        },
        confirmError: {
            type: Object
        },
        wizardCompleted: {
            type: Boolean
        }
    },
    methods: {
        toggleLiveAgent(){}
    }
}
</script>

<style>

</style>